@each $state, $data in $form-validation-states {
    .#{$state}-feedback {
        display: block;
        font-weight: $form-feedback-font-weight;
        line-height: $form-feedback-line-height;
        letter-spacing: $letter-spacing;
        text-transform: uppercase;
    }
}

.form-select,
.form-control,
.rmsc {
    .was-validated &:invalid:focus,
    &.is-invalid:focus,
    .was-validated &:valid:focus,
    &.is-valid:focus {
        border-color: $input-focus-border-color;
        box-shadow: none;
    }
}

.input-group > .form-select,
.input-group > .form-control,
.input-group > .rmsc {
    .was-validated &:valid,
    &.is-valid {
        + .input-group-text {
            border-color: $form-feedback-valid-color;
        }

        &:focus + .input-group-text {
            border-color: $input-focus-border-color;
        }
    }
    .was-validated &:invalid,
    &.is-invalid {
        + .input-group-text {
            border-color: $form-feedback-invalid-color;
        }

        &:focus + .input-group-text {
            border-color: $input-focus-border-color;
        }
    }
}
